/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from 'Utils/EventsEmitter';
import Sphere from './Sphere';
import Controls from './Controls/';
import WidgetsManager from './Widgets/WidgetsManager';
import PointerManager from 'Utils/PointerManager';
import Animation, { Easings } from 'Utils/Animation';
import { Config } from 'evr';
import Utils from 'Utils/Utils';
import Logger from 'Utils/Logger';
import Speaker from 'Utils/Speaker';
import _throttle from 'lodash/throttle';
import { round } from 'lodash';
import { VRButton } from "three/examples/jsm/webxr/VRButton";

function degToRad(deg) {
  return deg * 0.0174532925;
}

function openLinkInNewTab(url) {
  const newWindow = window.open(url);
  const language = window.navigator.userLanguage || window.navigator.language;
  if (!newWindow || newWindow.closed || typeof newWindow.closed=='undefined') {
    const message = language.includes('pl')
      ? 'Urządzenie ma włączoną blokadę okien. \nOtwórz ustawienia przeglądarki i wyłącz tę opcję aby otworzyć link.\nJeżeli korzystasz z przeglądarki Safari na urządzeniu moblinym z systemem iOS:\n1. Otwórz ustawienia urządzenia\n2. Przejdź do ustawień Sfari\n3. Wyłącz opcję "Blokuj okna wyskakujące"'
      : 'Your device has enabled block pop-ups settings. \nOpen browser settings and disable it to open link.\nIf you\'re using Safari on mobile device with iOS system:\n1. Open device settings\n2. Go to Safari settings\n3. Disable "Block pop-ups" option'
    window.alert(message);
  }
}


export default class Stage extends EventsEmitter {
  constructor({
    ui,
    player,
    project,
    stats,
    rotateScene,
    editor,
    scaleFactor,
    forceDesktopControls = false,
    enableGyroscope,
    linksEnabled,
    audioEnabled,
    videoSphericalEnabled,
    zoom = 1,
  }) {
    super();
    const that = this;
    this._player = player;
    this._editor = editor;
    this._project = project;
    this._ui = ui;
    this._stats = stats;
    this._state = {
      sceneId: null,
      immersiveVrSupported: false, // is vr supported
      inlineVrSupported: false, // inline vr supported
      vrPresenting: false, // is vr currently presenting
      vrSession: null,
      animating: false,
      autoplay: false,
      rotateScene: rotateScene,
      widgetsEnabled: true,
    };
    this.audioEnabled = audioEnabled;
    this.videoSphericalEnabled = videoSphericalEnabled;
    this.zoom = zoom;

    this._animations = new Animation();
    this.$l = new Logger('Stage');
    this._backgroundAudio; // holds scene background Audio object

    try {
      this._speaker = new Speaker();
    } catch (e) {
      this.$l.error(e);
    }

    this._scaleFactor = scaleFactor;

    this._mousePosition = null;
    this._raycaster = new THREE.Raycaster();
    this._renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
    this._renderer.xr.enabled = true;
    this._renderer.setClearColor('#fff');
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.sortObjects = true;
    ui.$.appendChild(this._renderer.domElement);
    this._camera = new THREE.PerspectiveCamera(75, 1/ 2, 0.1, 10000);
    this._camera.layers.enable( 1 );
    this._scene = new THREE.Scene();
    this._project.setMaxAnisotropy(this._renderer.capabilities.getMaxAnisotropy());
    const gl = this._renderer.getContext();
    const maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    this._project.setMaxTextureSize(maxTextureSize);

    this.initPromise = new Promise((resolve) => {
      Utils.Browser.vr().then(vrType => {
        if (vrType !== null) {
          if (vrType === 'immersive-vr') {
            this._state.immersiveVrSupported = true;
          } else {
            this._state.inlineVrSupported = true;
          }
          this._state.rotateScene = false;
        }
        this._controls.init({vrEnabled: !!vrType});
        resolve();
      });
    });

    this._crosshair = new THREE.Mesh(
      new THREE.CircleGeometry(0.15, 32),
      new THREE.MeshBasicMaterial({
        color: Config.player.primaryColor,
        transparent: true,
        opacity: 1,
        depthWrite: false,
        depthTest: false,
      })
    );
    this._crosshair.name = "crosshair";
    this._crosshair.position.set(0, 0, - Config.player.crosshairRadius);
    this._crosshair.lookAt(new THREE.Vector3(0, 0, 0));
    this._crosshair.renderOrder = Config.player.renderOrder.crosshair;
    this._crosshair.visible = editor;
    this._camera.add(this._crosshair);

    this.onResize();

    this._controls = new Controls({
      elementContainer: this._renderer.domElement,
      renderer: this._renderer,
      raycaster: this._raycaster,
      camera: this._camera,
      scene: this._scene,
      forceDesktopControls: forceDesktopControls,
      enableGyroscope: enableGyroscope,
      editor: editor,
    });
    this._scene.add(this._controls.$);

    // Hande canvas resizing
    this.onResize = this.onResize.bind(this);

    window.addEventListener('resize', this.onResize, true);
    window.addEventListener('vrdisplaypresentchange', this.onResize, true);

    // Handle pinch events
    const evCache = new Array();
    let prevDiff = -1;

    window.onpointerdown = pointerdownHandler;
    window.onpointermove = _throttle(pointermoveHandler, 100);

    window.onpointerup = pointerupHandler;
    window.onpointercancel = pointerupHandler;
    window.onpointerout = pointerupHandler;
    window.onpointerleave = pointerupHandler;

    function pointerdownHandler(ev) {
      evCache.push(ev);
    }

    function pointermoveHandler(ev) {
      const step = 0.2;
      const maxZoom = 4;
      const minZoom = 0.6;
      let newZoom;

      for (let i = 0; i < evCache.length; i++) {
        if (ev.pointerId == evCache[i].pointerId) {
          evCache[i] = ev;
          break;
        }
      }

      if (evCache.length == 2) {
        that._controls.disable();
        const curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);

        if (prevDiff > 0) {
          if (curDiff > prevDiff) {
            // Pinch moving OUT -> Zoom in
            newZoom = that.zoom >= maxZoom ? maxZoom : + that.zoom + step;
          }
          if (curDiff < prevDiff) {
            // Pinch moving IN -> Zoom out
            newZoom = that.zoom <= minZoom ? minZoom : + that.zoom - step;
          }
        }

        prevDiff = curDiff;
        that.zoom = newZoom ? newZoom.toFixed(2) : that.zoom.toFixed(2);
        that.setZoom({zoom: newZoom, animating: true});
        that.emit('pinch', that.zoom);
      }
    }

    function pointerupHandler(ev) {
      removeEvent(ev);

      if (evCache.length < 2) {
        prevDiff = -1;
        that._controls.enable();
        ev.stopPropagation();
      }

    }

    function removeEvent(ev) {
      for (let i = 0; i < evCache.length; i++) {
        if (evCache[i].pointerId == ev.pointerId) {
          evCache.splice(i, 1);
          break;
        }
      }
    }

    // Request animation frame loop function
    this._lastRender = 0;

    // Set up 3d objects
    this._sphere = new Sphere({
      state: this._state,
      project: this._project,
      videoSphericalEnabled,
    });
    this._scene.add(this._sphere.$);

    this._widgetsManager = new WidgetsManager({
      stage: this,
      scene: this._scene,
      project: this._project,
      editor: editor,
      speaker: this._speaker,
      animations: this._animations,
      linksEnabled,
      audioEnabled: this.audioEnabled,
    });

    this._widgetsManager.on('open', e => this.emit('openWidget', e));
    this._widgetsManager.on('close', e => this.emit('closeWidget', e));
    this._widgetsManager.on('action', (action) => {
      if (action) {
        const { type } = action;
        if (type === 'changeScene') {
          if (action.sceneId) {
            let { rotation } = action;
            if (rotation && !(rotation instanceof THREE.Vector3)) {
              rotation = new THREE.Vector3(degToRad(rotation.x), degToRad(rotation.y), 0);
            }
            if (action.sceneId === this._state.sceneId && action.rotation) {
              this.rotateCamera({ animate: true, rotation: rotation });
            } else {
              this.changeScene({
                sceneId: action.sceneId,
                animationType: action.animationType,
                rotation,
              });
            }
          }
        } else  if (type === 'openWidget') {
          const widget = this._widgetsManager._widgets[action.widgetId];
          if (widget) {
            if (!(widget._state.opening || widget._state.opened) &&
              !(this._controls._enabledGyroscope || this._state.vrPresenting)) {
              this.rotateCamera({ animate: true, rotation: widget._data.rotation });
            }
            widget.open(action.animate, action.context);
          }
        } else if (type === 'openLink') {
          this.openLink({
            url: action.url,
            text: action.text,
          });
        } else if (type === 'openProject') {
          this._project.loadProject(action.projectHash);
        }
      }
    });

    this._widgetsManager.on('toggleNearestWidget', e => {
      this.emit('toggleNearestWidget', e);
    });

    this._pointerManager = new PointerManager({scene: this._scene});

    this._draggedWidgets = [];
    // Handle controls events

    this._controls.on('click', (position) => {
      if (!this._state.vrPresenting && !editor && this._state.widgetsEnabled) {
        this._raycaster.setFromCamera(position, this._camera);

        const dir = this._raycaster.ray.direction;
        const rotation = new THREE.Vector3(-Math.asin(dir.y), Math.atan2(-dir.x, -dir.z), 0);
        const {
          targets,
          effective,
        } = this._widgetsManager.handleClick({ raycaster: this._raycaster });

        this.emit('click', {
          widget: targets[0],
          targets,
          position,
          rotation,
          effective,
        });
      }
    });

    this._controls.on('mouseDown', (position) => {
      this._animations.stop('rotateCamera');
      this._state.rotateScene = false;
      this.stopAutoplay();

      if (editor) {
        this._draggedWidgets = [];
        this._raycaster.setFromCamera(position, this._camera);

        const dir = this._raycaster.ray.direction.clone();
        const rotation = new THREE.Vector3(-Math.asin(dir.y), Math.atan2(-dir.x, -dir.z), 0);
        const targets = this._widgetsManager.intersectWidgets({ raycaster: this._raycaster });
        const widget = targets[0];

        // if clicked at least one widget, drag it
        if (widget) {
          if (widget.isHighlighted()) {
            const subRotation = rotation.clone().sub(widget._data.rotation);
            this._draggedWidgets.push({ widget, subRotation });
          } else {
            this._widgetsManager.highlightWidgets([widget._data.id]);
            widget.setHighlight(true);
          }
        }

        if (this._draggedWidgets.length) {
          this._controls.disableControls();
        }

        this.emit('click', {
          effective: !!widget,
          widget: targets[0],
          targets,
          position,
          rotation,
        });
      }
    });

    this._controls.on('mouseUp', () => {
      if (editor) {
        this._draggedWidgets = [];
        this._controls.enableControls();
      }
      this.emit('mouseUp', {});
    });

    this._controls.on('mouseMove', (coords) => {

      this._raycaster.setFromCamera(coords, this._camera);

      let dir = this._raycaster.ray.direction,
        position = (this._camera.position.clone()).add(dir.clone().multiplyScalar(Config.player.sphereRadius)),
        angleY = Math.atan2(-position.x, -position.z);

      if (editor) {
        if (this._draggedWidgets.length) {
          let widget = this._draggedWidgets[0].widget;


          let sub = this._draggedWidgets[0].subRotation;

          widget._data.rotation = new THREE.Vector3(-Math.asin(dir.y)-sub.x,
            Math.atan2(-dir.x, -dir.z)-sub.y,
            0);

          if (widget._data.rotation.x < -Math.PI/2) {
            widget._data.rotation.x = -Math.PI/2;
          }

          if (widget._data.rotation.x > Math.PI/2) {
            widget._data.rotation.x = Math.PI/2;
          }

          if (widget._data.rotation.y < -Math.PI) {
            widget._data.rotation.y += Math.PI*2;
          }

          if (widget._data.rotation.y > Math.PI) {
            widget._data.rotation.y -= Math.PI*2;
          }

          widget.updateRotationPosition();

          this.emit('widgetPositionChanged', widget._data);
        }
      } else {
        this._widgetsManager.handleMove(position);
      }

      position.normalize();
      this._scene.worldToLocal(position);

      this.emit('mouseMove', {dirPosition: position.clone(), position: Utils.parseXYZToDegrees(new THREE.Vector3(-Math.asin(position.y), angleY, 0))});
    });

    this._controls.on('drag', (coords) => {
      this.emit('drag');
    });

    // Turn off double tap for webview
    if (!Utils.Browser.webviewEvryplace) {
      this._controls.on('doubleClick', (options) => {
        this.rotateCamera({ rotation: options.rotation, animate: true});
      });
    }

    this._controls.on('mouseLeave', () => {
      this.emit('mouseLeave', {});
    });

    this._controls.on('drop', e => {
      this._raycaster.setFromCamera(e.coords, this._camera);

      let dir = this._raycaster.ray.direction;
      e.rotation = new THREE.Vector3(-Math.asin(dir.y), Math.atan2(-dir.x, -dir.z), 0);

      this.emit('drop', e);
    });

    this._controls.on('controllerDown', ({ controller }) => {
      const tempMatrix = new THREE.Matrix4();
      tempMatrix.identity().extractRotation( controller.matrixWorld );

      const raycaster = new THREE.Raycaster();
      raycaster.ray.origin.setFromMatrixPosition( controller.matrixWorld );
      raycaster.ray.direction.set( 0, 0, - 1 ).applyMatrix4( tempMatrix );

      this._widgetsManager.handleClick({ raycaster });
    });

    this._controls.on('controllerUp', params => {
      console.error(params);
    });
  }
  changeScene(options) {
    if (this._state.sceneId === options.sceneId) {
      return Promise.resolve();
    }
    this._animations.stop('rotateCamera');
    let newScene = this._project.getScene({sceneId: options.sceneId});

    return new Promise((resolve, reject) => {
      if (!newScene) {
        if (!this._state.sceneId) {
          this._sphere.setSphere([{id: 'spherePlaceholder'}]);
        }

        this.$l.warn('There\'s no scene with id: ' + options.sceneId);
        reject();
        return;
      }

      if (this._backgroundAudio) {
        this._backgroundAudio.pause();
        this._backgroundAudio = null;
      }

      if (newScene.audioResourceId && !this._editor && this.videoSphericalEnabled) {
        this._project.getResource({id: newScene.audioResourceId})
          .then((audioUrl) => {
            this._backgroundAudio = new Audio(audioUrl);
            this._backgroundAudio.play();
          });
      }

      const oldSceneHasVideo =
          !!this._sphere &&
          !!this._sphere._sphereMesh &&
          !!this._sphere._sphereMesh.material &&
          !!this._sphere._sphereMesh.material.uniforms &&
          !!this._sphere._sphereMesh.material.uniforms.sphereTexture.value &&
          this._sphere._sphereMesh.material.uniforms.sphereTexture.value.image instanceof HTMLVideoElement,
        video = oldSceneHasVideo ? this._sphere._sphereMesh.material.uniforms.sphereTexture.value.image : null;

      if (oldSceneHasVideo) {
        video.setAttribute('preload', 'none');
        video.oncanplaythrough = () => {};
        video.pause();
        video.currentTime = 0;
      }

      this._state.rotateScene = this._state.sceneId ? false : this._player._config.rotateScene; // only rotate first scene
      this._state.sceneId = options.sceneId;
      let newSpheres = this._project.getSpheres({sceneId: this._state.sceneId}),
        animation,
        rotation;

      if (options.animationType == "fadeToBlack" || options.animationType == "fadeToWhite") {
        animation = options.animationType;
      }

      if (options.rotation && options.rotation.y !== undefined) {
        rotation = options.rotation;
      }

      if (animation) {
        this._sphere._overlayMesh.material.color = options.animationType == "fadeToBlack" ? new THREE.Color(0x000000) : new THREE.Color(0xffffff);
        this._animations.create({
          length: 300,
          update: (progress) => {
            this._sphere._overlayMesh.material.opacity = progress;
          },
          ease: Easings.inQuad,
        }).promise
          .then(() => {
            this._sphere.setSphere(newSpheres);
            this._widgetsManager.reloadWidgets({widgets: newScene.widgets});
            if (rotation) {
              this._controls.rotateTo(rotation);
            }

            this._animations.create({
              length: 300,
              update: (progress) => {
                this._sphere._overlayMesh.material.opacity = 1 - progress;
              },
              ease: Easings.inQuad,
            }).promise
              .then(() => {
                resolve();
                this.emit('changeScene', options);
              });
          });
      } else {
        this._widgetsManager.reloadWidgets({widgets: newScene.widgets});
        this._sphere.setSphere(newSpheres);

        if (rotation) {
          this._controls.rotateTo(rotation);
        }

        this.once('render', () => {
          resolve();
          this.emit('changeScene', options);
        });
      }
    });
  }
  nextScene(loop = false, yRotationAdjustment = 0) {
    return new Promise((resolve, reject) => {
      const nextSceneId = this._project.getNextScene(this._state.sceneId, loop);
      const nextScene = this._project._data.scenes.find((scene) => scene.id === nextSceneId);

      if (nextScene) {
        this.changeScene({
          sceneId: nextSceneId,
          rotation: { y: nextScene.rotation.y + yRotationAdjustment },
        }).then(resolve, reject);
      } else {
        reject();
      }
    });
  }
  previousScene() {
    return new Promise((resolve, reject) => {
      let previousSceneId = this._project.getPreviousScene(this._state.sceneId);

      if (previousSceneId) {
        this.changeScene({sceneId: previousSceneId})
          .then(resolve, reject);
      } else {
        reject();
      }
    });
  }
  openLink(event) {
    const { url } = event;
    const isVieving = location.href.includes('/v/') || location.href.includes('/vh/');

    // detect if its link to other presentation
    const pPrefix = location.host + '/p/';
    const embedPrefix = location.host + '/embed/';
    const pIndex = url.indexOf(pPrefix);
    const embedIndex = url.indexOf(embedPrefix);
    if (pIndex > -1 || embedIndex > -1) {
      // determine end of presentation hash (ends with '-' , '#' or and of url)
      let dashPos = url.indexOf('-');
      let hashPos = url.indexOf('#');
      dashPos = dashPos > -1 ? dashPos : url.length;
      hashPos = hashPos > -1 ? hashPos : url.length;
      const endPos = Math.min(dashPos, hashPos);
      const pos = pIndex > -1 ? pIndex : embedIndex;
      const prefix = pIndex > -1 ? pPrefix : embedPrefix;
      event.hash = url.slice(pos + prefix.length, endPos);
      if (pIndex > -1) {
        event.presentation = true;
      } else {
        event.embed = true;
      }
    }

    if (event.embed || event.presentation) {
      if (isVieving) {
        openLinkInNewTab(event.url);
      } else {
        this.emit('changePresentation', event);
      }
    } else {
      if (!(Utils.Browser.isIOS() || window.IOS)) { // iOS app handles "openLink" events
        openLinkInNewTab(event.url);
      }
      this.emit('openLink', {
        url: event.url,
        text: event.text,
      });
    }
  }
  startAutoplay({animationLength = 40000, rotation = 180 }) {
    const startAutoplay = (initialRotation) => {
      if (this._state.autoplay) {
        return;
      }

      this._state.autoplay = true;

      if (
        !this._state.vrPresenting &&
        this._project._data.scenes &&
        this._project._data.scenes.length
      ) {
        this._state.rotateScene = false;

        const scene = this._project._data.scenes.find((s) => s.id === this._state.sceneId);
        const startRotation = scene ? scene.rotation : this.getCameraRotation();

        this._animations.create({
          id: 'autoplay',
          length: animationLength,
          update: (progress) => {
            let newRotation = {
              x: (initialRotation || startRotation).x * (1 - progress),
              y: initialRotation
                ? initialRotation.y - (progress * rotation)
                : startRotation.y  - ((progress - 0.5) * rotation),
            };

            this._controls.rotateTo(newRotation);
          }
        }).promise.then(() => {
          this.nextScene(true, 0.5 * rotation).then(() => {
            this._state.autoplay = false;
            startAutoplay();
            if (this._state.sceneId === this._project._data.initialState.sceneId) {
              this.once('render', () => {
                this.emit('autoplay.restart');
              });
            }
          }, () => {
          //change scene to the first one
            const rotation = this._project._data.initialState.rotation;
            this.changeScene({sceneId: this._project._data.initialState.sceneId, rotation})
              .then(() => {
                this._state.autoplay = false;
                startAutoplay();
                this.once('render', () => {
                  this.emit('autoplay.restart');
                });
              });
          });
        }, () => {});
      }
    };

    if (this._state.autoplay) {
      return;
    }
    startAutoplay(this.getCameraRotation());
    this.emit('autoplay.start');
  }
  stopAutoplay() {
    if (!this._state.autoplay) {
      return;
    }

    this._state.autoplay = false;
    this._animations.break('autoplay');
    this.emit('autoplay.stop');
  }
  enableRotateScene() {
    this._state.rotateScene = true;
  }
  disableRotateScene() {
    this._state.rotateScene = false;
  }
  getCameraRotation() {
    var cameraRotation,
      angleY;

    const cameraDirection = new THREE.Vector3();
    this._camera.getWorldDirection(cameraDirection);
    this._scene.worldToLocal(cameraDirection);
    angleY = Math.atan2(-cameraDirection.x, -cameraDirection.z);
    cameraRotation = new THREE.Vector3(-Math.asin(cameraDirection.y), angleY, 0);

    if (Math.abs(cameraDirection.y) >= 1) {
      cameraRotation.x = (cameraDirection.y < 0 ? 1 : -1) * Math.PI / 2;
    }

    if (cameraRotation.y == 2 * Math.PI) {
      cameraRotation.y = 0;
    }

    return cameraRotation;
  }
  rotateCamera(options) {
    const shouldAnimate = !!options.animate;

    if (!shouldAnimate) {
      this._controls.rotateTo(options.rotation);

      return;
    }

    let cameraRotation = this.getCameraRotation(),
      rotation = options.rotation,
      subRotation;

    if (rotation.y < -(Math.PI / 2) && cameraRotation.y > (Math.PI / 2)) {
      rotation.y = 2 * Math.PI + rotation.y;
      subRotation = cameraRotation.clone().sub(rotation);
    } else if (cameraRotation.y < -(Math.PI / 2) && rotation.y > (Math.PI / 2)) {
      rotation.y = rotation.y - 2 * Math.PI;
      subRotation = cameraRotation.clone().sub(rotation);
    } else {
      subRotation = cameraRotation.clone().sub(rotation);
    }

    subRotation.x = -subRotation.x;
    subRotation.y = -subRotation.y;

    this._animations.create({
      id: 'rotateCamera',
      length: 1000,
      update: (progress) => {
        let x = cameraRotation.x + (subRotation.x * progress),
          y = cameraRotation.y + (subRotation.y * progress);
        this._controls.rotateTo({x, y});
      },
      ease: Utils.easeInOutCubic
    });
  }
  createWidget(data) {
    this._widgetsManager.createWidget({data: data, project: this._project});
  }
  openInistialState() {
    const hasInitialState =
      this._project._data.initialState &&
      this._project._data.initialState.sceneId;

    if (!hasInitialState) { return; }

    this.changeScene({
      sceneId: this._project._data.initialState.sceneId,
      rotation: this._project._data.initialState.rotation
    });
  }
  enterVR() {
    return new Promise((resolve, reject) => {
      if (!this._state.immersiveVrSupported && !this._state.inlineVrSupported) {
        this.$l.warn('No vr display');
        reject();
      }

      const onSessionEnded = () => {
        this._controls.onVRSessionEnd();
        this._state.vrSession.removeEventListener( 'end', onSessionEnded);
        this._state.vrSession = null;
        this._state.vrPresenting = false;
        this.emit('vrSessionEnd');
      };

      const sessionInit = { optionalFeatures: [ 'local-floor', 'bounded-floor', 'hand-tracking' ] };
      const vrType = this._state.immersiveVrSupported ? 'immersive-vr' : 'inline-vr';
      navigator.xr.requestSession(vrType, sessionInit).then(vrSession => {
        vrSession.addEventListener( 'end', onSessionEnded);
        this._state.vrPresenting = true;

        const { xr } = this._renderer;
        xr.setSession(vrSession).then(() => {
          this._state.vrSession = vrSession;
          this.once('render', () => {
            this._controls.onVRSessionStart();
          });
          resolve();
        });
      }, (error) => {
        this.$l.error('Vr session aborter due to: ', error.message);
        reject();
      });
    });
  }
  requestExit() {
    if (this._state.vrSession) {
      this._state.vrSession.end();
    }
  }
  animate(timestamp, frame) {
    if (this._stats) { this._stats.begin(); }

    let delta = Math.min(timestamp - this._lastRender, 500),
      raycaster;

    this._scene.updateMatrixWorld();
    this._lastRender = timestamp;

    this._animations.tick(delta);
    this._controls.update();
    this._pointerManager && this._pointerManager.animate({delta});
    this._renderer.render(this._scene, this._camera);

    if (this._state.rotateScene) {
      this._controls.rotateY(-0.0005);
    }

    if (this._state.vrPresenting) {
      // calculate  & emit camera rotation events
      const camera = this._renderer.xr.getCamera(this._camera);
      const elements = camera.matrixWorld.elements;
      const cameraRotation = new THREE.Vector3(-elements[8], -elements[9], -elements[10]).normalize();
      this.emit('cameraRotate', Utils.parseXYZToDegrees(cameraRotation));
      this.emit('cameraViewportChanged', this.getCameraViewport(camera));

      //provide raycaster for VR presenting
      const controller = this._controls._vrControls.getController();

      if (controller) {
        const tempMatrix = new THREE.Matrix4();
        tempMatrix.identity().extractRotation( controller.matrixWorld );

        this._raycaster.ray.origin.setFromMatrixPosition( controller.matrixWorld );
        this._raycaster.ray.direction.set( 0, 0, - 1 ).applyMatrix4( tempMatrix );

        let dir = this._raycaster.ray.direction,
          position = (this._raycaster.ray.origin.clone()).add(dir.clone().multiplyScalar(Config.player.sphereRadius));

        this._widgetsManager.handleMove(position);
      } else {
        // If there is no controller, use head to interact with widgets
        this._raycaster.setFromCamera({x: 0, y: 0}, this._camera);
        raycaster = this._raycaster;
      }
    }

    if (this._state.vrEnabled) {
      if (this._controls._enabledEvents) {
        this._widgetsManager.animate({delta: delta, raycaster: raycaster});
      } else {
        this._widgetsManager.animate({delta: delta});
      }
    } else {
      if (!Utils.Browser.mobile) {
        this._widgetsManager.animate({delta: delta, raycaster: raycaster, mousePosition: this._mousePosition});
      } else {
        this._widgetsManager.animate({delta: delta, raycaster: raycaster});
      }
    }
    if (raycaster) {
      this._scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 300, 0xff0000) );
    }
    this.emit('render');

    if (this._stats) this._stats.end();
  }
  getCameraViewport(camera) {
    camera = camera || this._camera;
    let unproject = (x, y) => {
      var ret = (new THREE.Vector3(x, y, 1)).unproject(camera);
      ret = ret.sub(camera.position).normalize();
      this._scene.worldToLocal(ret);
      return ret;
    };

    var w = 1,
      h = 1;

    if (this._state.vrPresenting) {
      w = 0.8;
      h = 0.8;
    }

    return {
      lt: unproject(-w, h),
      lb: unproject(-w, -h),
      rt: unproject(w, h),
      rb: unproject(w, -h),
      c: unproject(0, 0),
    };
  }

  startAnimate() {
    this._state.animating = true;

    this._renderer.setAnimationLoop(this.animate.bind(this));
  }
  stopAnimate() {
    this._renderer.setAnimationLoop(null);
    this._state.animating = false;
  }
  onResize() {
    const width = this._ui.$.offsetWidth * this._scaleFactor;
    const	height = this._ui.$.offsetHeight * this._scaleFactor;
    const ratio = width / height;

    this._camera.aspect = ratio;
    this._camera.updateProjectionMatrix();
    this._renderer.setSize(width, height);
  }

  setZoom({zoom = 1, animating = false}) {
    if (animating) {
      let startZoom = this._camera.zoom;

      this._animations.stop('camera-zoom');
      this._animations.create({
        id: 'camera-zoom',
        length: 500,
        update: (progress) => {
          this._camera.zoom = startZoom + progress * (zoom - startZoom);
          this._camera.updateProjectionMatrix();
        },
      });
    } else {
      this._camera.zoom = zoom;
      this._camera.updateProjectionMatrix();
    }
    this.zoom = zoom;
  }
  disableWidgets() {
    this._state.widgetsEnabled = false;
  }
  enableWidgets() {
    this._state.widgetsEnabled = true;
  }
  unload() {
    this.stopAnimate();
    this._renderer.clear();
    if (this._backgroundAudio) {
      this._backgroundAudio.pause();
    }
    this._widgetsManager.reloadWidgets({widgets: []});
    this._sphere.remove();
    this._renderer.dispose();
    this._state.sceneId = null;
  }
  /**
   * Clear all event listeners
   */
  remove() {
    this.stopAnimate();
    if (this._backgroundAudio) {
      this._backgroundAudio.pause();
    }
    this._widgetsManager.reloadWidgets({widgets: []});
    this._sphere.remove();
    this._controls.remove();
    window.removeEventListener('resize', this.onResize, true);
    window.removeEventListener('vrdisplaypresentchange', this.onResize, true);
    this._state.animating = false;
  }
}

